import { Button, CardActions, LinkButton } from '@atoms';
import { ErrorCard } from '@molecules';
import { useI18n } from '@hooks/i18n';
import { Sidebar } from '@layouts';
import { useRouter } from 'next/router';
import React from 'react';

const PageUnknown = () => {
  const { t } = useI18n();
  const { back } = useRouter();

  return (
    <Sidebar>
      <ErrorCard
        content={t('404-message-unknown-page', {
          notes: 'Error message when visiting an un known page',
        })}
        title={t('404-title-unknown-page', {
          notes: 'Error title when visiting an unknown page',
        })}
        renderFooter={(
          <CardActions>
            <Button onClick={back} outline>{t('404-button-step-back')}</Button>
            <LinkButton href="/dashboard">{t('404-button-to-dashboard')}</LinkButton>
          </CardActions>
        )}
      />
    </Sidebar>
  );
};

export default PageUnknown;
